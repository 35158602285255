<template>
    <div class="page-wrapper">
        <div class="content">
            <!-- Page Header -->
            <breadcrumb :title="strTitle" :text="strSubTitle" :path="strPath" />
            <!-- /Page Header -->
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="profile-tabs">
                                <ul class="nav nav-tabs nav-tabs-bottom">
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: activeTab === 'appointment' }" @click="activeTab = 'appointment'" href="#appointment" data-bs-toggle="tab">Consulta</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: activeTab === 'test' }" @click="activeTab = 'test'" href="#test" data-bs-toggle="tab">Pruebas fisicas</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane show active" id="appointment">
                                      <ConsultationForm :isEdit="true" :id="id" :parentId="parentId" :recordId="recordId"/>
                                    </div>
                                    <div class="tab-pane" id="test">
                                      <PatientTestList :id="id" :historyId="parentId" :appointmentId="recordId"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import axios from "@/axios.js";
  import PatientTestList from "../patient-test/PatientTestList.vue";
  import ConsultationForm from "./ConsultationForm.vue";

  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
      parentId: {
        type: String,
        default: null,
      },
      recordId: {
        type: String,
        default: null,
      },
    },
    components: {
      PatientTestList,
      ConsultationForm,
    },
    data() {
      const strTitle = "Paciente";
      return {
        activeTab: 'appointment',
        strTitle,
        strSubTitle: "Consulta",
        strPath: "/patients/view",
        objPaciente: {},
      };
    },
    async created() {
      await this.getPaciente();
      this.strTitle = `Historia Clinica #${this.parentId} (${this.objPaciente.strNombreCompleto})`;
      this.strPath = `/patient/${this.id}/medical-history/${this.parentId}/consultation/list`;
    },
    methods: {
      async getPaciente() {
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}`);
          this.objPaciente = objResponse.data;
          this.objPaciente.strNombreCompleto = `${objResponse.data.nombre} ${objResponse.data.apellido_paterno} ${objResponse.data.apellido_materno}`;
        } catch (error) {
          console.error(`Error al obtener paciente: `, error);
        }
      },
    },
  };
</script>