<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="form-heading">
                <h4>Información adicional</h4>
              </div>
            </div>
            <template v-for="(field, index) in lstFields" :key="index">
              <div :class="field.strClass">
                <div class="input-block local-forms">
                  <template v-if="field.type !== 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                      class="form-control"
                      :type="field.type"
                      v-model="objForm[field.name]"
                      :placeholder="field.placeholder"
                      :required="field.required"
                      :disabled="field.disabled"
                      @change="handleInputField(field.name, $event, 'input')"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                      :options="field.options || []"
                      v-model="objForm[field.name]"
                      placeholder="Selecciona una opción"
                      @select="handleInputField(field.name, $event, 'select')"
                      :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

const lstFields = [
  { name: "telefono_emergencia", label: "Teléfono de Emergencia", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "nombre_emergencia", label: "Nombre de Emergencia", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_parentesco", label: "Parentesco", type: "select", required: false, strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_estado_civil", label: "Estado Civil", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_ocupacion", label: "Ocupación", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_esquema_laboral", label: "Esquema Laboral", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_tipo_paciente", label: "Tipo de Paciente", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_estatus_paciente", label: "Estatus de Paciente", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "id_sucursal", label: "Sucursal", type: "select", required: false, options: [], strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
  { name: "telefono_trabajo", label: "Teléfono de Trabajo", type: "text", required: false, strClass: "col-12 col-md-6 col-xl-4", bAdicional: true },
];

export default {
  props: {
    objData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lstCatalagos: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      lstFields,
      objForm: {},
    };
  },
  emits: ['paciente'],
  watch: {
    objData: {
      handler: function (value) {
        this.objForm = value;
      },
      immediate: true,
    },
    lstCatalagos: {
      handler: function (value) {
        if(value.estadosCiviles) this.updateFieldOptions('id_estado_civil', 'nombre', value.estadosCiviles);
        if(value.ocupaciones) this.updateFieldOptions('id_ocupacion', 'nombre', value.ocupaciones);
        if(value.esquemasLaborales) this.updateFieldOptions('id_esquema_laboral', 'nombre', value.esquemasLaborales);
        if(value.tiposPacientes) this.updateFieldOptions('id_tipo_paciente', 'nombre', value.tiposPacientes);
        if(value.estatusPacientes) this.updateFieldOptions('id_estatus_paciente', 'nombre', value.estatusPacientes);
        if(value.sucursales) this.updateFieldOptions('id_sucursal', 'nombre', value.sucursales);
        if(value.parentescos) this.updateFieldOptions('id_parentesco', 'nombre', value.parentescos);
      },
      immediate: true,
    },
  },
  async created() {
    this.objForm = this.objData;
  },
  methods: {
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      
      if (field && Array.isArray(objData))
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));

      if (field && !Array.isArray(objData))
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
    },
    handleInputField(strFieldName, event, strType) {
      const strValue = strType === 'input' ? event.target.value : event.id;
      this.objForm[strFieldName] = strValue;
      this.$emit('paciente',  this.objForm);
    },
  },
};
</script>
