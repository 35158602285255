import axios from '@/axios';
import { jwtDecode } from 'jwt-decode';

export default {
  async login(email, password, captchaToken) {
    try {
      let objUser = {
        usuario: email,
        contrasena: password,
        captchaToken: captchaToken
      }
      const response = await axios.post(`/usuario/login`, objUser);
      if (response.data.token) {
        localStorage.setItem('objToken', response.data.token);
        const token = localStorage.getItem('objToken');
        const decodedToken = jwtDecode(token);
        localStorage.setItem('objUser', JSON.stringify(decodedToken));
        return { success: true };
      } else {
        console.error("Error en la solicitud de login:", response.data);
        return { success: false, error: 'Credenciales invalidas' };
      }
    } catch (error) {
      if (error.response) {
        // El servidor respondió con un código de estado fuera del rango 2xx
        return { success: false, error: error.response.data.error};
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        console.error("Error en la solicitud de login:", error);
        return { success: false, error: 'network_error' };
      } else {
        // Algo sucedió al configurar la solicitud que desencadenó un error
        console.error("Error en la solicitud de login:", error);
        return { success: false, error: 'network_error' };
      }
    }
  },
  logout() {
    // Eliminar el token de localStorage
    localStorage.removeItem('objToken');
    localStorage.removeItem('objUser');
  },
  async verifyToken() {
    try {
      const response = await axios.get('/usuario/verifyToken', {
        headers: {
          'Authorization': `Bearer ${this.getToken()}`
        }
      });
      return response.data.valid;
    } catch (error) {
      return false;
    }
  },
  getToken() {
    return localStorage.getItem('objToken');
  }
};