import axios from 'axios';
import usuarios from '@/services/usuarios';
import { notification } from "ant-design-vue";

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`, // Cambia esto a la URL de tu backend
  timeout: 30000, // Tiempo de espera en milisegundos
  headers: { 'Content-Type': 'application/json' }
});

// Configurar el interceptor de solicitudes para incluir el token
instance.interceptors.request.use(
  config => {
    const token = usuarios.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error(error);
    if(error.response && error.response.data.code === 403) {
      const strError = error.response.data.error;
      if(strError === 'TokenExpiredError') {
        usuarios.logout();
        notificationError('Su sesión ha expirado');
      }
         
    } 
    return Promise.reject(error);
  }
);

const notificationError = (message) => {
  notification.error({
    message: 'info',
    description: message
  });
};

export default instance;
