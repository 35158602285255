<template>
  <!-- Page Wrapper -->
  <div class="row">
      <div class="col-sm-12">
        <div class="card card-table show-entire">
          <div class="card-body">
            <!-- Table Header -->
            <div class="page-table-header mb-2">
              <div class="row align-items-center">
                <div class="col">
                  <div class="doctor-table-blk">
                    <h3>Nuevo Paciente</h3>
                    <div class="doctor-search-blk">
                      <div class="add-group">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-form action="javascript:;" @submit.prevent="submitForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <template v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false">
                      <template v-if="field.type != 'select'">
                        <div :class="field.strClass">
                          <div class="input-block local-forms">
                            <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                            <b-form-input
                              :type="field.type"
                              v-model="objForm[field.name]"
                              :placeholder="field.label"
                              :required="field.required"
                              :disabled="field.disabled"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </template>
                      <template v-if="field.type == 'select'">
                        <div :class="field.strClass">
                          <div class="input-block local-forms">
                            <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                            <vue-select
                              :options="field.options || []"
                              v-model="objForm[field.name]"
                              @select="handleSelectField(field.name, $event)"
                              :placeholder="field.label"
                            />
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <b-button @click="handleCancel" variant="secondary previous me-2">Cancelar</b-button>
                <b-button type="submit" variant="btn btn-primary next" v-if="bGuardar == false">Siguiente</b-button>
                  <b-button variant="btn btn-primary next" v-if="bGuardar" >
                    <b-spinner class="spinner-border-sm me-1"></b-spinner>
                    Cargando...
                  </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  <!-- /Page Wrapper -->
</template>
<script>
import axios from "@/axios";
import { notification } from "ant-design-vue";
import Spinner from "@/components/Spinner.vue";

const lstFields = [
  { name: "nombre", label: "Nombre", type: "text", required: true, strClass: "col-md-6" },
  { name: "apellido_paterno", label: "Apellido Paterno", type: "text", required: true, strClass: "col-md-6" },
  { name: "apellido_materno", label: "Apellido Materno", type: "text", required: false, strClass: "col-md-6" },
  { name: "fecha_nacimiento", label: "Fecha de Nacimiento", type: "date", required: true, strClass: "col-md-6" },
  { name: "id_genero", label: "Género", type: "select", required: true, options: [], strClass: "col-md-6" },
  { name: "id_tipo_paciente", label: "Tipo de Paciente", type: "select", required: false, options: [], strClass: "col-md-6" },
  { name: "correo_electronico", label: "Correo Electrónico", type: "email", required: false, strClass: "col-md-6" },
  { name: "telefono_celular", label: "Teléfono Celular", type: "text", required: true, strClass: "col-md-6" },
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, Number],
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "select", "onFormPaciente"],
  data() {
    const strTitle = "Pacientes";
    return {
      bSpinner: false,
      bGuardar: false,
      objForm: this.handleInitForm(),
      lstFields,
    };
  },
  components: {
    Spinner,
  },
  async created() {
    await this.getCatalogos();
    this.objForm = this.handleInitForm();
  },
  methods: {
    handleInitForm(){
      return {
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        id_genero: "",
        id_tipo_paciente: "",
        id_estatus_paciente: "",
        correo_electronico: "",
        telefono_celular: "",
      };
    },
    async getCatalogos() {
      try {
        const objResponse = await axios.get('/catalogos/pacientes');

        this.updateFieldOptions('id_genero', 'nombre', objResponse.data.generos);
        this.updateFieldOptions('id_tipo_paciente', 'nombre', objResponse.data.tiposPacientes);
        this.updateFieldOptions('id_estatus_paciente', 'nombre', objResponse.data.estatusPacientes);

      } catch (error) {
        console.error('Error al obtener datos relacionados:', error);
      }
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      
      if (field && Array.isArray(objData))
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));

      if (field && !Array.isArray(objData))
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }

      this.objForm[strFieldName] = strValue;
    },
    async submitForm() {
      this.bGuardar = true;
      const lstFields = this.lstFields.filter(objField => objField.required);
      for (const objField of lstFields) {
        if (!this.objForm[objField.name]) {
          alert(`El campo ${objField.label} es requerido.`);
          return;
        }
      }

      try {
        const objResponse = await axios.post("/pacientes", this.objForm);
        this.openNotification('Paciente registrado correctamente');
        this.$emit('paciente', objResponse.data);
      } catch (error) {
        console.log('Error al registrar paciente:', error);
      }
      this.bGuardar = false;
    },
    handleCancel() {
      this.$emit('onFormPaciente', false);
    },
    openNotification(strMessage) {
      notification.open({
        message: strMessage,
        placement: "bottomRight",
        bottom: "50px",
        duration: 3,
        style: {
          width: "250px",
          marginLeft: `${335 - 600}px`,
          background: "#2ab57d",
        },
      });
    },
  },
};
</script>
