<template>
    <div class="row">
      <div class="col-sm-12">
        <div class="card card-table show-entire">
          <div class="card-body">
            <!-- Table Header -->
            <div class="page-table-header mb-2">
              <div class="row align-items-center">
                <div class="col">
                  <div class="doctor-table-blk">
                    <h3>Historial Medico</h3>
                    <div class="doctor-search-blk">
                      <div class="top-nav-search table-search-blk">
                        <form @submit.prevent="handleSearch">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Buscar historia"
                            v-model="strSearchQuery"
                          />
                          <a class="btn"
                            ><img src="@/assets/img/icons/search-normal.svg" alt=""
                          /></a>
                        </form>
                      </div>
                      <div class="add-group">
                        <router-link
                          :to="{ name: 'add-medical-history', params: { id: id } }"
                          class="btn btn-primary add-pluss ms-2">
                          <img src="@/assets/img/icons/plus.svg" alt=""/>
                        </router-link>
                        <a
                          href="javascript:;"
                          class="btn btn-primary doctor-refresh ms-2"
                          @click="getData">
                          <img src="@/assets/img/icons/re-fresh.svg" alt=""/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Table Header -->

            <!-- Table -->
            <div class="table-responsive">
              <a-table
                class="table border-0 custom-table comman-table datatable mb-0"
                :columns="lstColumns"
                :dataSource="handleFilterData"
                :rowKey="record => record.id_historia_clinica"
                :pagination="{ pageSize: 5 }"
                :loading="bSpinner">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id_historia_clinica'">
                    <router-link :to="{ name: 'edit-medical-history', params: { id: this.id, recordId: record.id_historia_clinica } }" >
                      MEDHIS-#{{ record.id_historia_clinica }}
                    </router-link>
                  </template>
                  <template v-if="column.key === 'consulta'">
                    <div>
                      <router-link class="btn btn-sm btn-primary" :to="{ name: 'patient-history-consultation', params: { id: record.id_paciente, parentId: record.id_historia_clinica } }">
                        Ver consultas
                      </router-link>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <i class="fa fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <router-link  :to="{ name: 'patient-history-consultation', params: { id: record.id_paciente, parentId: record.id_historia_clinica } }" 
                              class="dropdown-item" >
                              <i class="fa-solid fa-eye m-r-5"></i>
                                Ver consultas</router-link>
                          <router-link :to="{ name: 'edit-medical-history', params: { id: this.id, recordId: record.id_historia_clinica } }" 
                            class="dropdown-item" >
                            <i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Editar</router-link>
                          <!--a class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_patient">
                            <i class="fa fa-trash-alt m-r-5"></i> Eliminar
                          </a-->
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
            <!-- /Table -->
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import axios from "@/axios";

  function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = (typeof aValue === 'string' && aValue !== null ) ? aValue.toLowerCase() : aValue;
          const bValueStr = (typeof bValue === 'string' && bValue !== null ) ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "Historia", strDataIndex: "id_historia_clinica", strKey: "id_historia_clinica"},
    { strTitle: "Fecha registro", strDataIndex: "fecha_creacion", strKey: "fecha_creacion", bFilter: true },
    { strTitle: "Motivo consulta", strDataIndex: "id_motivo_consulta", strKey: "id_motivo_consulta", bFilter: true },
    { strTitle: "Cirugia", strDataIndex: "cirugia", strKey: "cirugia" },
    { strTitle: "Consultas", strDataIndex: "consulta", strKey: "consulta" },
    { strTitle: "", strDataIndex: "action", strKey: "action", strClass: "text-end" },
  ];

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];
  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
      bLoading: {
        type: Boolean,
        default: false,
      },
      lstDataParam: {
        type: Array,
        default: () => [],
      },
    },
    emits: ["update:modelValue", "select"],
    data() {
      return {
        bSpinner: false,
        strSearchQuery: "",
        lstData,
        lstColumns,
        lstRowSelected,
        lstColumnsToCreate,
      };
    },
    computed: {
      handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
        const query = this.strSearchQuery.toLowerCase();

        return this.lstData.filter((objData) => {
          return this.lstColumnsToCreate.some((objColumn) => {
            if (!objColumn.bFilter) return false;
            const strValue = objData[objColumn.strDataIndex];
            const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
            return strValueSearch.includes(query);
          });
        });
      },
      handleRowSelection(){
        return {
          selectedRowKeys: this.lstRowSelected,
          onChange: this.handleRowSelectionChange,
        }
      }
    },
    created(){
      this.loadData();
    },
    watch: {
      lstDataParam: {
        handler(newVal) {
          this.loadData();
        },
        deep: true,
      },
      bLoading: {
        handler(newVal) {
          this.bSpinner = newVal;
        },
        immediate: true,
      },
    },
    methods: {
      loadData() {
        try {
          this.lstData = this.lstDataParam;
        } catch (error) {
          console.error(`Error al obtener historial medico: `, error);
        }
      },
      async getData() {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}/historias-clinicas`);
          this.lstData = objResponse.data.map(objHistoria => ({
            ...objHistoria,
            fecha_creacion: new Date(objHistoria.fecha_creacion).toISOString().split('T')[0],
            id_motivo_consulta: objHistoria.motivo_consulta?.nombre ?? '',
            cirugia: objHistoria.cirugia ? 'Si' : 'No',
          }));
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
        this.bSpinner = false;
      },
      handleRowSelectionChange(selectedRowKeys) {
        this.lstRowSelected = selectedRowKeys;
      },
      handleSearch() {
        // Este método se llama automáticamente debido a la propiedad computada `filteredData`
      },
    }
  };
</script>