<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalle Sucursal</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <div class="col-12 col-md-6 col-xl-4" v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false">
                <div class="input-block local-forms">
                  <template v-if="field.type === 'text' || field.type === 'date'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <input
                    class="form-control"
                    :type="field.type"
                    v-model="objForm[field.name]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :disabled="field.disabled"
                    @input="updateValue"
                    />
                  </template>
                  <template v-if="field.type === 'select'">
                    <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                    <vue-select
                    :options="field.options || []"
                    v-model="objForm[field.name]"
                    placeholder="Selecciona una opción"
                    @select="handleSelectField(field.name, $event)"
                    :disabled="field.disabled"
                    />
                  </template>
                </div>
              </div>
              <div class="col-12">
                <b-button @click="submitForm" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import { notification } from "ant-design-vue";
import Spinner from "@/components/Spinner.vue";

const lstFields = [
{ name: "nombre", label: "Nombre", type: 'text', required: true},
{ name: "codigo", label: "Codigo", type: 'text', required: true},
{ name: "id_empresa", label: "Empresa", type: 'select', required: true, options: []},
{ name: "telefono", label: "Telefono", type: 'text'},
{ name: "calle", label: "Calle", type: 'text'},
{ name: "numero_interior", label: "Numero Interior", type: 'text'},
{ name: "numero_exterior", label: "Numero Exterior", type: 'text'},
{ name: "id_codigo_postal", label: "Codigo Postal", type: 'select', required: true, options: []},
{ name: "id_pais", label: "Pais", type: 'select', required: true, options: [], disabled: true },
{ name: "id_localidad", label: "Localidad", type: 'select', required: true, options: [], disabled: true },
{ name: "id_ciudad", label: "Ciudad", type: 'select', required: true, options: [], disabled: true },
{ name: "id_colonia", label: "Colonia", type: 'select', required: true, options: []},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, Number],
      required: true,
    },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      objForm: this.handleInitForm(),
      lstFields,
    };
  },
  emits: ['submit', 'cancel', 'select'],
  components: {
    Spinner,
  },
  async created() {
    if(!this.isEdit) this.objForm = this.handleInitForm();

    await this.getCatalogos();
    if(this.isEdit) await this.getData();
  },
  methods: {
    handleInitForm(){
      return {
        nombre: "",
        codigo: "",
        id_empresa: "",
        telefono: "",
        calle: "",
        numero_interior: "",
        numero_exterior: "",
        id_codigo_postal: "",
        id_pais: "",
        id_localidad: "",
        id_ciudad: "",
        id_colonia: ""
      };
    },
    async getData(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/sucursales/${this.id}`);
        this.objForm = objResponse.data;
        this.objForm.id_colonia = String(objResponse.data.id_colonia);
        this.objForm.id_codigo_postal = String(objResponse.data.id_codigo_postal);
        this.objForm.id_ciudad = String(objResponse.data.id_ciudad);
        this.objForm.id_localidad = String(objResponse.data.id_localidad);
        this.objForm.id_pais = String(objResponse.data.id_pais);

        this.lstFields.forEach((objField) => {
          if (objField.type === 'select' && objField.options) {
            const objOption = objField.options.find((option) => option.id === this.objForm[objField.name]);
            if (objOption) this.objForm[objField.name] = objOption.id;
          }
        });
        
        if (this.objForm.id_codigo_postal) {
          await this.getRelatedDataByPostalCode(this.objForm.id_codigo_postal);
        }
        this.$emit("update:modelValue", this.objForm);
        
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    async getCatalogos(){
      this.bSpinner = true;
      try {
        const objResponse = await axios.get("/catalogos/sucursales");
        this.updateFieldOptions("id_empresa", "nombre", objResponse.data.empresas);
        this.updateFieldOptions("id_colonia", "nombre", objResponse.data.colonias);
        this.updateFieldOptions("id_codigo_postal", "codigo_postal", objResponse.data.codigosPostales);
        this.updateFieldOptions("id_ciudad", "nombre", objResponse.data.ciudades);
        this.updateFieldOptions("id_localidad", "nombre", objResponse.data.localidades);
        this.updateFieldOptions("id_pais", "nombre", objResponse.data.paises);
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
      this.bSpinner = false;
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      let lstSelected = ['id_pais', 'id_localidad', 'id_ciudad'];
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      
      if (field && Array.isArray(objData))
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));

      if (field && !Array.isArray(objData))
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];

      if(lstSelected.includes(strFieldIndex))
        this.objForm[strFieldIndex] = field.options[0].id;
    },
    async getRelatedDataByPostalCode(strCodigoPostal) {
      try {
        const objCodigoPostal = await axios.get(`/codigos-postales/${strCodigoPostal}/colonias`);

        this.updateFieldOptions('id_pais', 'nombre', objCodigoPostal.data.pais);
        this.updateFieldOptions('id_localidad', 'nombre', objCodigoPostal.data.localidad);
        this.updateFieldOptions('id_ciudad', 'nombre', objCodigoPostal.data.ciudad);
        this.updateFieldOptions('id_colonia', 'nombre', objCodigoPostal.data.colonias);
      } catch (error) {
        console.error('Error al obtener datos por código postal:', error);
      }
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }

      this.objForm[strFieldName] = strValue;
      this.$emit("update:modelValue", strValue);

      if (strFieldName === "id_codigo_postal") this.getRelatedDataByPostalCode(strValue);
    },
    async submitForm() {
      this.bGuardar = true;
      const lstFields = this.lstFields.filter(objField => objField.required);
      for (const objField of lstFields) {
        if (!this.objForm[objField.name]) {
          alert(`El campo ${objField.label} es requerido.`);
          return;
        }
      }
      
      try{
        if(this.isEdit) await axios.put(`/sucursales/${this.id}`, this.objForm);
        //if(!this.isEdit) await axios.post("/sucursales", this.objForm);
        this.openNotification('Sucursal registrada correctamente');
        this.$router.go(-1);
      }catch(error){
        console.error('Error al registrar consulta:', error);
      }
      this.bGuardar = false;
    },
    emitSelectEvent() {
      this.$emit('select', { /* datos del evento */ });    
    },
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    handleCancel() {
      this.$router.go(-1);
    },
    openNotification(strMessage) {
      notification.open({
        message: strMessage,
        placement: "bottomRight",
        bottom: "50px",
        duration: 3,
        style: {
          width: "250px",
          marginLeft: `${335 - 600}px`,
          background: "#2ab57d",
        },
      });
    },
  },
};
</script>