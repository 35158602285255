<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Detalle Historia Clinica</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <template v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false" >
                <div :class="field.strClass">
                  <div class="input-block local-forms">
                    <template v-if="field.type === 'range'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <div class="card-body roundskin-slider">
                        <Slider v-model="objForm[field.name]" class="roundskin" :min="0" :max="10" @change="handleRange" />
                      </div>
                    </template>
                    <template v-if="field.type === 'text' || field.type === 'date'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <input
                        class="form-control"
                        :type="field.type"
                        v-model="objForm[field.name]"
                        :placeholder="field.placeholder"
                        :required="field.required"
                        :disabled="field.disabled"
                      />
                    </template>
                    <template v-if="field.type === 'textarea'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <b-form-textarea
                        id="textarea"
                        v-model="objForm[field.name]"
                        rows="5"
                        max-rows="6"
                        :required="field.required"
                        placeholder="Enter text here">
                      </b-form-textarea>
                    </template>
                    <template v-if="field.type === 'select'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <vue-select
                        :options="field.options || []"
                        v-model="objForm[field.name]"
                        placeholder="Selecciona una opción"
                        @select="handleSelectField(field.name, $event)"
                        :disabled="field.disabled"
                        :required="field.required"
                      />
                    </template>
                    <template v-if="field.type === 'signature'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                        <template v-if="bFirma == false">
                          <Vue3Signature :ref="el => lstSignatureRefs[index] = el" :sigOption="objSignatureConfig.options" 
                          :w="'100%'" :h="'400px'" :disabled="objSignatureConfig.disabled" />
                          <b-button @click="handleClearSignature(index)" :disabled="objSignatureConfig.disabled" variant="secondary me-1">Limpiar firma</b-button>
                        </template>
                        <img :src="strImage" width="70%" alt="Imagen en base64" v-if="bFirma" />
                    </template>
                  </div>
                </div>
              </template>
              <div class="col-12">
                <div>
                  <b-button type="submit" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                  <b-button variant="primary me-1" v-if="bGuardar" >
                    <b-spinner class="spinner-border-sm me-1"></b-spinner>
                    Guardando...
                  </b-button>
                  <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row" v-if="isEdit">
          <div class="col-sm-6 col-12">
            <small class="text-muted">Creación: {{ objForm.strUsuarioCreacion }}, {{ objForm.strFechaCreacion }}</small>
          </div>
          <div class="col-sm-6 col-12 text-end">
            <small class="text-muted">Actualización: {{ objForm.strUsuarioActualizacion }}, {{ objForm.strFechaActualizacion }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from "vue";
  import axios from "@/axios";
  import Slider from "@vueform/slider";
  import Vue3Signature from "vue3-signature";
  import { format } from 'date-fns';
  import Spinner from "@/components/Spinner.vue";

  const lstSignatureRefs = ref([]);
  const objSignatureConfig = {
    options: {
      canvasWidth: 800,
      canvasHeight: 400,
      backgroundColor: "rgb(249, 249, 249)",
      penColor: "rgb(0, 0, 0)",
      velocityFilterWeight: 0.7,
      minWidth: 0.5,
      maxWidth: 2.5,
      throttle: 16,
      minPointDistance: 3,
    },
    disabled: false,
  };

  const lstValues = [
    { id: "1", text: "Sí" },
    { id: "0", text: "No" },
  ];

  const lstFields = [
    { name: "id_motivo_consulta", label: "Motivo consulta", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
    { name: "fecha_padecimiento", label: "Fecha padecimiento", type: "date", required: true, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "escala_dolor", label: "Escala dolor", type: "range", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "diagnostico", label: "Diagnostico", type: "textarea", required: true, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "fecha_inicio_tratamiento", label: "Fecha inicio tratamiento", type: "date", required: true, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "diagnostico_medico", label: "Diagnostico médico", type: "textarea", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "cirugia", label: "Cirugía", type: "select", required: false, options: lstValues, strClass: "col-12 col-md-6 col-xl-4"},
    { name: "fecha_cirugia", label: "Fecha cirugía", type: "date", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "fecha_alta", label: "Fecha alta cirugía", type: "date", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "firma", label: "Firma", type: 'signature', strClass: "col-md-12 col-xl-12"},
  ];

  export default {
    props: {
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
      },
      recordId: {
        type: String,
        default: null,
      },
    },
    components: {
      Slider,
      Vue3Signature,
      Spinner,
    },
    data() {
      return {
        bSpinner: false,
        bGuardar: false,
        bFirma: false,
        strPath: "/patients/view",
        strImage: "",
        objPaciente: {},
        objForm: this.handleInitForm(),
        objSignatureConfig,
        lstFields,
        lstValues,
        lstSignatureRefs,
      };
    },
    emits: ['submit', 'cancel', 'select'],
    async created() {
      if(!this.isEdit) this.objForm = this.handleInitForm();

      if(this.isEdit) await this.getHistory();

      this.strPath = `/patient/${this.id}/profile`;
      this.getData('id_motivo_consulta', 'nombre', '/motivos-consulta');
    },
    methods: {
      handleInitForm(){
        return {
          id_motivo_consulta: "",
          fecha_padecimiento: new Date().toISOString().split("T")[0],
          escala_dolor: "",
          diagnostico: "",
          fecha_inicio_tratamiento: new Date().toISOString().split("T")[0],
          id_medico: "",
          diagnostico_medico: "",
          cirugia: "1",
          fecha_cirugia: "",
          fecha_alta: "",
          id_objetivo: "",
        };
      },
      async getHistory(){
        this.bSpinner = true;
        try {
          let strFirma = '';
          const objResponse = await axios.get(`/historias-clinicas/${this.recordId}`);
          this.objForm = objResponse.data;
          this.objForm.id_motivo_consulta = String(objResponse.data.id_motivo_consulta);
          this.objForm.fecha_padecimiento = objResponse.data.fecha_padecimiento ? objResponse.data.fecha_padecimiento.split("T")[0] : null;
          this.objForm.fecha_inicio_tratamiento = objResponse.data.fecha_inicio_tratamiento ? objResponse.data.fecha_inicio_tratamiento.split("T")[0] : null;
          this.objForm.fecha_cirugia = objResponse.data.fecha_cirugia ? objResponse.data.fecha_cirugia.split("T")[0] : null;
          this.objForm.fecha_alta = objResponse.data.fecha_alta ? objResponse.data.fecha_alta.split("T")[0] : null;
          this.objForm.cirugia = String(objResponse.data.cirugia);
          this.objForm.strFechaCreacion = objResponse.data.fecha_creacion ? format(new Date(objResponse.data.fecha_creacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
          this.objForm.strUsuarioCreacion = objResponse.data.usuario_creacion ? `${objResponse.data.usuario_creacion.nombre} ${objResponse.data.usuario_creacion.apellido_paterno}` : 'No disponible';
          this.objForm.strFechaActualizacion = objResponse.data.fecha_actualizacion ? format(new Date(objResponse.data.fecha_actualizacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
          this.objForm.strUsuarioActualizacion = objResponse.data.usuario_actualizacion ? `${objResponse.data.usuario_actualizacion.nombre} ${objResponse.data.usuario_actualizacion.apellido_paterno}` : 'No disponible';

          strFirma = this.objForm.firma;
          if(strFirma != null && this.objForm.firma.length > 5){
            this.bFirma = true;
            this.strImage = strFirma;
            /*
            this.objSignatureConfig.disabled = true;
            this.lstSignatureRefs.forEach((objRef) => {
              if (objRef && this.objForm.firma) {
                objRef.fromDataURL(this.objForm.firma);
              }
            });
            */
          }
        } catch (error) {
          console.error(`Error al obtener historia clinica: `, error);
        }
        this.bSpinner = false;
      },
      async getData(strIndexField, strFieldName, strEndpoint) {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(strEndpoint);
          const objData = objResponse.data;
          this.lstFields.find((field) => field.name === strIndexField).options = objData.map((item) => ({
            id: String(item[strIndexField]),
            text: String(item[strFieldName]),
          }));
        } catch (error) {
          console.error(`Error al obtener datos de ${strEndpoint}: `, error);
        }
        this.bSpinner = false;
      },
      handleRange(value) {
        this.objForm.escala_dolor = value;
      },
      handleSelectField(strFieldName, strValue) {
        if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
        if (typeof strValue.id === "number") {
          strValue = String(strValue.id);
        } else if (typeof strValue.text === "number") {
          strValue = String(strValue.text);
        } else {
          strValue = strValue.id || strValue.text || "";
        }

        this.objForm[strFieldName] = strValue;
      },
      handleClearSignature(index) {
        this.lstSignatureRefs[index].clear();
      },
      async submitForm() {
        if(!this.validateForm()) return;
        this.bGuardar = true;
        this.objForm.id_paciente = this.id;
        this.objForm.firma = this.lstSignatureRefs.filter(objRef => objRef !== null && !objRef.isEmpty())
        .map(objRef => objRef.save('image/png', 1, 0.5))[0];

        try{
          if(this.isEdit) await axios.put(`/historias-clinicas/${this.recordId}`, this.objForm);
          if(!this.isEdit) await axios.post("/historias-clinicas", this.objForm);
          this.toast('success', true, 'Historia registrada correctamente');
          this.$router.go(-1);
        }catch(error){
          console.error('Error al registrar historia clinica:', error);
          this.toast('error', true, 'Ocurrio un error al registrar la historia clinica, intente nuevamente.');
        }
        this.bGuardar = false;
      },
      validateForm() {
        const lstRequiredFields = this.lstFields.filter((field) => field.required);
        const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
        if (lstInvalidFields.length > 0) {
          this.toast('error', true, 'Favor de llenar todos los campos requeridos');
          return false;
        }
        return true;
      },
      handleCancel() {
        this.$router.go(-1);
      },
      toast(type = "default", dismissible = true, message = "") {
        const options = {
          dismissible,
          onClick: this.onClick,
          duration: 3000,
        };
        typeof type === "string" && (options.type = type);
        typeof options.maxToasts === "string" &&
          (options.maxToasts = parseInt(options.maxToasts));

        this.$toast.show(message, {
          ...options,
        });
      },
    },
  };
</script>
<style>
  .roundskin .slider-connect {
    background: var(--slider-connect-bg, #2e37a4) !important;
    cursor: pointer;
    height: 4px;
  }
  .roundskin .slider-base,
  .slider-connects {
    height: 4px;
    position: relative;
    width: 100%;
    border-radius: 4px;
  }
  .roundskin .slider-tooltip {
    background: var(--slider-tooltip-bg, #006cfa) !important;
    border: 1px solid var(--slider-tooltip-bg, #006cfa) !important;
    border-radius: var(--slider-tooltip-radius, 5px);
    color: var(--slider-tooltip-color, #fff);
    display: block;
    font-size: var(--slider-tooltip-font-size, 0.875rem);
    font-weight: var(--slider-tooltip-font-weight, 600);
    line-height: var(--slider-tooltip-line-height, 1.25rem);
    min-width: var(--slider-tooltip-min-width, 20px);
    padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
    position: absolute;
    text-align: center;
    white-space: nowrap;
  }
  .roundskin-slider .slider-horizontal .slider-handle {
    top: calc(
      (var(--slider-handle-height, 30px) - var(--slider-height, 6px)) / 2 * -1 + 2px
    );
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    border: 4px solid #006cfa;
    background-color: white;
    border-radius: 24px;
    box-shadow: none;
  }
</style>
<style src="@vueform/slider/themes/default.css"></style>