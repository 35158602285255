<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0"></h4>
        </div>
        <div class="card-body">
          <div class="wizard">
            <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
              <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top" title="Información del paciente">
                <div class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center"
                  :class="{ active: tabId === 1 }" href="#step1" @click="tabId = 1" id="step1-tab" data-bs-toggle="tab" role="tab" 
                  aria-controls="step1" aria-selected="tabId === 1">
                  <i class="far fa-user"></i>
                </div>
              </li>
              <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top" title="Información del adicional">
                <div class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center"
                  :class="{ active: tabId === 2 }" href="#step2" @click="tabId = 2" id="step2-tab" data-bs-toggle="tab" role="tab" 
                  aria-controls="step2" aria-selected="tabId === 2">
                  <i class="fa fa-address-card"></i>
                </div>
              </li>
              <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top" title="Información del geografica">
                <div class="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center"
                  :class="{ active: tabId === 3 }" href="#step3" @click="tabId = 3" id="step3-tab" data-bs-toggle="tab" role="tab" 
                  aria-controls="step3" aria-selected="tabId === 3">
                  <i class="fas fa-map-pin"></i>
                </div>
              </li>
            </ul>

            <Spinner v-if="bSpinner" />

            <div class="tab-content" id="myTabContent" v-if="bSpinner == false">
              <div class="tab-pane fade" :class="{ show: tabId === 1, active: tabId === 1 }" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                <PatientFormPersonal :objData="objForm" :lstCatalagos="lstCatalagos" @paciente="handleChangeData"/>
              </div>

              <div class="tab-pane fade" :class="{ show: tabId === 2, active: tabId === 2 }" role="tabpanel" id="step2" aria-labelledby="step2-tab">
                <PatientFormAdditional :objData="objForm" :lstCatalagos="lstCatalagos" @paciente="handleChangeData"/>
              </div>

              <div class="tab-pane fade" :class="{ show: tabId === 3, active: tabId === 3 }" role="tabpanel" id="step3" aria-labelledby="step3-tab">
                <PatientFormAddress :objData="objForm" :lstCatalagos="lstCatalagos" @paciente="handleChangeData"/>
              </div>
              <div class="d-flex">
                <b-button @click="submitForm" variant="primary me-1" v-if="bGuardar == false" >Guardar Registro</b-button>
                <b-button variant="primary me-1" v-if="bGuardar" >
                  <b-spinner class="spinner-border-sm me-1"></b-spinner>
                  Guardando...
                </b-button>
                <b-button @click="handleCancel" variant="secondary me-1">Cancelar</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import Spinner from "@/components/Spinner.vue";
import PatientFormPersonal from "./PatientFormPersonal.vue";
import PatientFormAdditional from "./PatientFormAdditional.vue";
import PatientFormAddress from "./PatientFormAddress.vue";

const lstFields = [
  { name: "nombre", required: true },
  { name: "apellido_paterno", required: true },
  { name: "fecha_nacimiento", required: true },
  { name: "id_genero", required: true },
  { name: "telefono_celular", required: true },
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Array, Number],
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "select"],
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      tabId: 1,
      objForm: {},
      lstFields,
      lstCatalagos: [],
    };
  },
  components: {
    Spinner,
    PatientFormPersonal,
    PatientFormAdditional,
    PatientFormAddress
  },
  async created() {
    await this.getCatalogos();
    if (this.isEdit && this.id) {
      await this.getPaciente();
    }
    //if(!this.isEdit) this.objForm = this.handleInitForm();
  },
  methods: {
    handleInitForm(){
      return {
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        id_genero: "",
        id_estado_civil: "",
        id_ocupacion: "",
        id_esquema_laboral: "",
        id_tipo_paciente: "",
        id_estatus_paciente: "",
        id_sucursal: "",
        correo_electronico: "",
        telefono_casa: "",
        telefono_celular: "",
        telefono_trabajo: "",
        telefono_emergencia: "",
        nombre_emergencia: "",
        id_parentesco: "",
        id_codigo_postal: "",
        id_pais: "",
        id_localidad: "",
        id_ciudad: "",
        id_colonia: "",
        calle: "",
        numero_interior: "",
        numero_exterior: "",
      };
    },
    async getPaciente() {
      this.bSpinner = true;
      try {
        const objResponse = await axios.get(`/pacientes/${this.id}`);
        this.objForm = objResponse.data;
        this.objForm.fecha_nacimiento = new Date(this.objForm.fecha_nacimiento).toISOString().split('T')[0];
        this.objForm.id_genero = String(objResponse.data.id_genero);
        this.objForm.id_estado_civil = String(objResponse.data.id_estado_civil);
        this.objForm.id_ocupacion = String(objResponse.data.id_ocupacion);
        this.objForm.id_esquema_laboral = String(objResponse.data.id_esquema_laboral);
        this.objForm.id_tipo_paciente = String(objResponse.data.id_tipo_paciente);
        this.objForm.id_estatus_paciente = String(objResponse.data.id_estatus_paciente);
        this.objForm.id_sucursal = String(objResponse.data.id_sucursal);
        this.objForm.id_parentesco = String(objResponse.data.id_parentesco);
        this.objForm.id_codigo_postal = String(objResponse.data.id_codigo_postal);
        this.objForm.id_pais = String(objResponse.data.id_pais);
        this.objForm.id_localidad = String(objResponse.data.id_localidad);
        this.objForm.id_ciudad = String(objResponse.data.id_ciudad);
        this.objForm.id_colonia = String(objResponse.data.id_colonia);

        this.$emit("update:modelValue", this.objForm);
        this.bSpinner = false;
      } catch (error) {
        console.error(`Error al obtener paciente: `, error);
        this.bSpinner = false;
      }
    },
    async getCatalogos() {
      try {
        this.bSpinner = true;
        const objResponse = await axios.get('/catalogos/pacientes');
        this.lstCatalagos = objResponse.data;
        this.bSpinner = false;
      } catch (error) {
        console.error('Error al obtener datos relacionados:', error);
        this.bSpinner = false;
      }
    },
    handleChangeData(objData) {
      this.objForm = objData;
    },
    async submitForm() {
      if(!this.validateForm()) return;
      let objResponse = {};
      this.bGuardar = true;
      try {  
        if (this.isEdit) objResponse = await axios.put(`/pacientes/${this.id}`, this.objForm);
        if (!this.isEdit) objResponse = await axios.post("/pacientes", this.objForm);
        this.toast('success', true, 'Paciente registrado correctamente');
        this.$router.push({ name: 'patient-profile', params: { id: objResponse.data.id_paciente } });
      } catch (error) {
        console.error('Error al registrar paciente:', error);
        this.toast('error', true, 'Error al registrar paciente, intente de nuevo.');
      }
      this.bGuardar = false;
    },
    validateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objForm[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>
