<template>
  <div class="container-fluid px-0">
    <div class="row">
      <!-- Login logo -->
      <div class="col-lg-6 login-wrap">
        <div class="login-sec">
          <div class="log-img">
            <img class="img-fluid" src="@/assets/img/login-02.png" alt="Logo" />
          </div>
        </div>
      </div>
      <!-- /Login logo -->

      <!-- Login Content -->
      <div class="col-lg-6 login-wrap-bg">
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="login-right">
              <div class="login-right-wrap">
                <h2>Login</h2>
                <!-- Form -->
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <div class="input-block">
                    <label>Correo <span class="login-danger">*</span></label>
                    <Field
                      name="email"
                      type="text"
                      v-model="form.email"
                      class="form-control"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div class="invalid-feedback">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                  </div>
                  <div class="input-block">
                    <label>Contraseña <span class="login-danger">*</span></label>
                    <Field
                      name="password"
                      :type="bShowPassword ? 'text' : 'password'"
                      v-model="form.password"
                      class="form-control pass-input mt-2"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span @click="toggleShow" class="toggle-password">
                      <i
                        :class="{
                          'fas fa-eye': bShowPassword,
                          'fas fa-eye-slash': !bShowPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                  <div class="input-block">
                    <vue-recaptcha-v3 action="login" />
                  </div>
                  <div class="input-block login-btn">
                    <button class="btn btn-primary btn-block" type="submit" v-if="bIniciar == false">Iniciar Sésion</button>
                    <b-button variant="btn btn-primary btn-block" v-if="bIniciar" >
                      <b-spinner class="spinner-border-sm me-1"></b-spinner>
                      Iniciando...
                    </b-button>
                  </div>
                  <div v-if="strError" class="alert alert-danger mt-2" id="credentials">
                    {{ strError }}
                  </div>
                </Form>
                <!-- /Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Login Content -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import usuarios from "@/services/usuarios";
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';

export default {
  components: {
    Form,
    Field,
    'vue-recaptcha-v3': VueReCaptcha,
  },
  data() {
    return {
      bShowPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.bShowPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      console.log(this.$route.path);
      this.bShowPassword = !this.bShowPassword;
    },
    async onSubmit(values) {
      this.bIniciar = true;
      try {
        const captchaToken = await this.executeRecaptcha('login');
        await this.schema.validate(values, { abortEarly: false });
        this.errors = {};
        const response = await usuarios.login(values.email, values.password, captchaToken);
        if (response.success) {
          this.$router.push('/dashboard');
        } else {
         this.strError = response.error;
        }
      } catch (validationErrors) {
        if (validationErrors.inner) {
          validationErrors.inner.forEach(error => {
            this.errors[error.path] = error.message;
          });
        }
        console.log(validationErrors);
      }
      this.bIniciar = false;
    },
    onVerifyCaptcha(token) {
      console.log('Captcha verified:', token);
    },
  },
  setup() {
    const { executeRecaptcha } = useReCaptcha();
    const strError = ref('');
    const bIniciar = ref(false);
    const errors = ref({});
    const form = ref({
      email: '',
      password: '',
      remember: false,
    });
    const schema = Yup.object().shape({
      email: Yup.string().required("Correo requerido").email("Correo no válido"),
      password: Yup.string()
        .min(6, "La contraseña debe de contener al menos 6 caracteres")
        .required("Contraseña requerida"),
    });

    return {
      executeRecaptcha,
      bIniciar,
      strError,
      form,
      errors,
      schema,
      checked: ref(false),
    };
  },
};
</script>

<style scoped>
/* Estilos personalizados si es necesario */
</style>